import FileDownload from "js-file-download";

export default class FileManager {
    static getFileSizeKB(file) {
        if (file) {
            return (file.size / 1024).toFixed(2)
        }

        return 0
    }
}

// ### EXAMPLE
// res = response type arraybuffer ที่ได้จาก api
// downloadFileFromResponse(res) or downloadFileFromResponse(res, "file_name.txt")
export function downloadFileFromResponse(response, propFileName) {
    let fileName = 'DownloadFile.txt';

    if (propFileName) {
        fileName = propFileName;
    } else {
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition && contentDisposition.includes('attachment')) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch?.length === 2) fileName = fileNameMatch[1];
        }
    }

    FileDownload(response.data, fileName)
}