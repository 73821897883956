import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import axios from "axios";
import ApiSendEmailBu from "../../services/api/ApiSendEmailBu";
import Autocomplete from "@mui/material/Autocomplete";
import LinearProgress from '@mui/material/LinearProgress';
import nl2br from "react-nl2br";
import { format } from "date-fns";
import {API} from "../../config/api";

function SendMailBu() {
  const pathApi = new API().getCusAPI();

const [loading, setLoading] = useState(false)

  // ------------ ดึงข้อมูลมาแสดง ---------
  const [dataInvoice, setDataInvoice] = useState([]);

  const { register: register1, handleSubmit: handleSubmitSearch } = useForm();

  const getDataInvoiceBu = (data) => {

    const formData = new FormData();
    formData.append("dateSearch", data["dateSearch"]);
    formData.append("round", data["rounds"]);
    formData.append("service_type",data["service_type"]);
    formData.append("stetus",data["stetus"]);
    setLoading(true);
    axios
      .post(pathApi + "getDataInvoiceEmail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setDataInvoice(response.data.data);
        setLoading(false);
        console.log(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {

    const emptyData = {
      dateSearch: defaultDate,
      rounds: '',
      service_type: '',
    };
    setLoading(true);
    getDataInvoiceBu(emptyData);
  }, []);

  // ------------ End ดึงข้อมูลมาแสดง ------

  // ------------ form send email bu ------

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    handleClose();

    const formData = new FormData();
    formData.append("file_zip", data["file_zip"][0]);
    formData.append("title", data["title"]);
    formData.append("detail", data["detail"]);
    formData.append("round", data["round"]);
    setLoading(true);
    const response = ApiSendEmailBu.postZipFileEmailVmp(formData)
      .then(function (response) {

        const result = response.data.data;
        console.log(result);
        const formDataResult = new FormData();
        if (result) {
          formDataResult.append("id_file_on_storage", result[0]["id_file_on_storage"]);
          formDataResult.append("title", result[0]["title"]);
          formDataResult.append("detail", result[0]["detail"]);
          formDataResult.append("round", result[0]["round"]);
        } else {
          console.log("ไม่มีข้อมูลใน result");
        }

        const sendDataForUnZip = ApiSendEmailBu.postZipFileEmail(formDataResult)
            .then(function (response) {
              const resultSendEmail = response.data;
              console.log(resultSendEmail);

              Swal.fire({
                title: "ส่ง Email เรียบร้อยแล้ว",
                // text: result.message,
                icon: "success",
                confirmButtonText: "OK",
              }).then();

              setLoading(false);
            })

      })
      .catch((error) => {
        handleClose();
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        }).then();
      });

    console.log(watch());
    console.log(errors);
  };

  // ------------ form send email bu -------


  // ------------ Modal --------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [openDetail, setOpenDetail] = React.useState(false);

  const handleOpenDetail = (arrayDetail) => {
    setParamsDataRows(arrayDetail);
    setOpenDetail(true);
  };

  const [paramsDataRows, setParamsDataRows] = React.useState([]);

  console.log(paramsDataRows.arrayDetail);
  const handleCloseDetail = () => setOpenDetail(false);

  const styleDetail = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // ------------ End Modal ----------------

  // ------------ Table --------------------
  const columns = [
    // { field: 'id', headerName: 'id', flex: 1  },
    { field: "date", headerName: "วันที่", flex: 1 },
    { field: "idCustomer", headerName: "รหัสลูกค้า", flex: 1 },
    { field: "types", headerName: "ประเภทงาน", flex: 1 },
    { field: "roundsDetail", headerName: "รอบงาน", flex: 2 },
    {
      field: "title",
      headerName: "Title",
      flex: 3,
      renderCell: (params) => {
        const DetailRemark = params.row.remark;
        const DetailCode = params.row.idCustomer;
        const DetailName = params.row.name;
        const DetailRound = params.row.roundsDetail;
        const DetailEmail = params.row.email;
        const DetailTitle = params.row.title;
        const DetailDetail = params.row.detail;
        const DetailServiceType = params.row.types;
        const DetailStatus = params.row.remark;
        const DetailDateCreate = params.row.date;
        const DetailRailedReason = params.row.railedReason;

        const arrayDetail = [
            { 'DetailRemark': DetailRemark },
            { 'DetailCode': DetailCode },
            { 'DetailName': DetailName },
            { 'DetailRound': DetailRound },
            { 'DetailEmail': DetailEmail },
            { 'DetailTitle': DetailTitle },
            { 'DetailDetail': DetailDetail },
            { 'DetailServiceType': DetailServiceType },
            { 'DetailStatus': DetailStatus },
            { 'DetailDateCreate': DetailDateCreate },
            { 'DetailRailedReason': DetailRailedReason },
        ];

        const cellStyle = {
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        };

        return (
          <>
            <Button
              onClick={() => handleOpenDetail({ arrayDetail })}
              variant="text"
              size="small"
              style={cellStyle}

            >
              {params.row.title}
            </Button>
          </>
        );
      },
    },
    // { field: "email", headerName: "Email", flex: 2 },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      renderCell: (params) => {
        const emails = params.row.email.split(', '); // แยกเมล์ตาม comma และเว้นวรรค
        const cellStyleEmail = {
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          lineHeight: '15px',
          fontSize: '14px'
        };

        return (
            <div style={cellStyleEmail}>
              {emails.map((email, index) => (
                  <React.Fragment key={index}>
                    {email}
                    {index < emails.length - 1 && <br />} {/* ใส่ <br> ก่อนถ้าไม่ใช่เมล์สุดท้าย */}
                  </React.Fragment>
              ))}
            </div>
        );
      },
    },
    {
      field: "status",
      headerName: "สถานะ",
      flex: 1,
      renderCell: (params) => {
        const status_send = params.row.remark;
        return (
          <>
            {status_send === "ส่งสำเร็จ" ? (
              <Typography
                variant="h6"
                component="h2"
                style={{ color: "green" }}
              >
                ส่งสำเร็จ
              </Typography>
            ) : (
              <Typography variant="h6" component="h2" style={{ color: "red" }}>
                ส่งไม่สำเร็จ
              </Typography>
            )}
          </>
        );
      },
    },
    // { field: "note", headerName: "หมายเหตุ", flex: 1 },
  ];


  const rows = dataInvoice.map((item, index) => ({
    id: index,
    date: format(new Date(item.dateCreate.date), "dd-MM-yy HH:mm:ss"),
    idCustomer: item.code,
    name: item.name,
    title: item.title,
    detail: item.detail,
    types: item.serviceType,
    roundsDetail: item.roundsDetail,
    email: item.email,
    status: item.status,
    remark: item.remark,
    railedReason: item.railedReason,
    note: "-",
  }));

  // ------------ End Table ------------------

  const serviceType = ["Cross Dock_Chill", "Super Sub"];
  const stetus = ["ส่งสำเร็จ", "ส่งไม่สำเร็จ"];

  // ------------ กำหนดค่าคงที่ รอบ ------------------
  const formatDateRound = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1; // เดือนเริ่มนับจาก 0
    const year = date.getFullYear();

    if (day <= 15) {
      const endOfMonth = new Date(year, month, 0).getDate(); // หาวันสิ้นเดือนของเดือนนั้น

      return `16-${endOfMonth}.${month.toString().padStart(2, '0')}.${year}`;
    } else {
      return `1-15.${month.toString().padStart(2, '0')}.${year}`;
    }
  };

  const [defaultRound, setDefaultRound] = useState('');

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = formatDateRound(currentDate);
    setDefaultRound(formattedDate);
  }, []);
// ----------------------------------------------------------

  const [defaultDate, setDefaultDate] = useState('');

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  useState(() => {
    const currentDate = getCurrentDate();
    setDefaultDate(currentDate);
  }, []);

  return (
    <>
      {loading && <LinearProgress />}
      <form onSubmit={handleSubmitSearch(getDataInvoiceBu)}>
        <Grid container spacing={2} sx={{ padding: "25px" }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  {...register1("dateSearch")}
                  fullWidth
                  size={"small"}
                  type="date"
                  defaultValue={defaultDate}
                  label="เลือกวันที่"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  {...register1("rounds")}
                  fullWidth
                  size={"small"}
                  label="รอบงาน"
                  defaultValue={''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  fullWidth
                  size={"small"}
                  disablePortal
                  defaultValue={''}
                  id="combo-box-demo"
                  options={serviceType}
                  renderInput={(params) => (
                    <TextField
                      {...register1("service_type")}
                      {...params}
                      label="ประเภทงาน"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  fullWidth
                  size={"small"}
                  disablePortal
                  defaultValue={''}
                  id="combo-box-demo"
                  options={stetus}
                  renderInput={(params) => (
                    <TextField
                      {...register1("stetus")}
                      {...params}
                      label="สถานะ"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Stack direction="row" spacing={2}>
              <Button fullWidth variant="outlined" type="submit">
                ค้นหา
              </Button>
              <Button fullWidth variant="contained" onClick={handleOpen}>
                ส่ง Email
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>

      <Grid container spacing={2} sx={{ padding: "25px" }}>
        {/*<Grid item xs={12}>*/}
        {/*  <div style={{ height: 500, width: "100%" }}>*/}
        {/*    <DataGrid*/}
        {/*      rows={rows}*/}
        {/*      columns={columns}*/}

        {/*      rowHeight={70}*/}
        {/*      initialState={{*/}
        {/*        pagination: {*/}
        {/*          paginationModel: { page: 0, pageSize: 100 },*/}
        {/*        },*/}
        {/*        sorting: {*/}
        {/*          sortModel: [{ field: 'date', sort: 'desc' }],*/}
        {/*        },*/}
        {/*      }}*/}
        {/*      pageSizeOptions={[50, 100 , 150]}*/}
        {/*    />*/}

        {/*  </div>*/}
        {/*</Grid>*/}

          <Grid item xs={12}>
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                  rows={rows}
                  columns={columns}
                  rowHeight={70}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 100 },
                    },
                    sorting: {
                      sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                  }}
                  pageSizeOptions={[50, 100, 150]}
              />
            </div>
          </Grid>


      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ส่ง Email
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size={"small"}
                    id="outlined-basic"
                    label="เขียนหัวข้อ Title"
                    variant="outlined"
                    {...register("title", { required: true })}
                    error={!!errors.title}
                    helperText={errors.title ? "กรุณากรอกข้อมูลให้ครบ" : ""}
                    defaultValue={
                      "ใบแจ้งหนี้ลูกค้า {รหัสลูกค้า} {ชื่อลูกค้า} {ประเภทงาน} {รอบขนส่ง}"
                    }
                  />
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={12}
                    size={"small"}
                    id="outlined-basic"
                    label="รายละเอียด"
                    variant="outlined"
                    {...register("detail", { required: true })}
                    error={!!errors.detail}
                    helperText={errors.detail ? "กรุณากรอกข้อมูลให้ครบ" : ""}
                    defaultValue={
                      "เรียน ฝ่ายบัญชี\n\n{แจ้งเรื่อง}\n\n{จัดส่ง}\nโซเฟียร์งะสมัน (สินเชื่อ)\n\n{ชื่อและที่อยู่}\n\nTel : 02-090-9139\nEmail : sofianga@allnow.co.th\n\nหมายเหตุ : {หมายเหตุ}"
                    }
                  />
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size={"small"}
                    id="outlined-basic"
                    label="รอบ"
                    variant="outlined"
                    defaultValue={defaultRound}
                    {...register("round", { required: true })}
                    error={!!errors.round}
                    helperText={errors.round ? "กรุณากรอกข้อมูลให้ครบ" : ""}
                  />
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size={"small"}
                    type="file"
                    {...register("file_zip", { required: true })}
                  />
                </Grid>
              </Grid>

              <Stack direction="row" sx={{ paddingTop: "20px" }}>
                <Button fullWidth variant="contained" type="submit">
                  ส่ง Email{" "}
                </Button>
              </Stack>
            </form>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openDetail}
        onClose={handleCloseDetail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        arrayDetail={paramsDataRows.arrayDetail}
      >
        <Box sx={styleDetail}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            รายละเอียดการส่ง Email
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                วันที่ : { paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[9].DetailDateCreate }
              </Grid>
              <Grid item xs={4}>
                สถานะ : <span style={{color: paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[8].DetailStatus === 'ส่งสำเร็จ' ?  "green" : "red"}}>{paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[8].DetailStatus === 'ส่งสำเร็จ'? 'ส่งสำเร็จ' : 'ส่งไม่สำเร็จ'} </span>
              </Grid>
              <Grid item xs={4}>
                หมายเหตุ : { paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[10].DetailRailedReason }
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                รหัสลูกค้า : {paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[1].DetailCode}
              </Grid>
              <Grid item xs={4}>
                ชื่อลูกค้า : {paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[2].DetailName}
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                ประเภทงาน : {paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[7].DetailServiceType}
              </Grid>
              <Grid item xs={4}>
                รอบ : {paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[3].DetailRound}
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                ส่งถึง : {paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[4].DetailEmail}
              </Grid>
              <Grid item xs={12}>
                Title : {paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[5].DetailTitle}
              </Grid>
              <Grid item xs={12}>
                Detail : <br />

                { nl2br( paramsDataRows.arrayDetail && paramsDataRows.arrayDetail[6].DetailDetail ) }

              </Grid>
            </Grid>
          </Typography>
        </Box>
      </Modal>


    </>
  );
}

export default SendMailBu;
